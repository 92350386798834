import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TelegramPost } from '../components/telegram-post'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle} indexPage>
        <SEO
          title="All posts"
          keywords={[`blog`, `javascript`, `react`, 'django', 'python', 'tips']}
        />
        {
          posts.map(({ node }) => (
              <TelegramPost key={node.fields.slug} postId={node.fields.slug}/>
          ))
        }
      </Layout>
    )
  }
}

const BlogPost = styled.div`
  margin: 32px 0 96px;
`

const BlogPostTitle = styled.h3`
  margin: 0;
`

const BlogPostDate = styled.small`
  margin: 4px 0 4px;
`

const Excerpt = styled.div`
  margin: 0;
`

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { 
        fields: [frontmatter___date], 
        order: DESC,
      },
      filter: {
        fileAbsolutePath: {
          regex: "\/tgposts/"
        },
      },
    ) {
      edges {
        node {
          html
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
          }
        }
      }
    }
  }
`
